.small-card1OP {
  width: 18%;
  /* box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1); */
  border-radius: 12px;
  height: 97%;
  display: flex;
  flex-direction: column;
}

.small-card1-bodyOP {
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1em;
}
.small-card-imgOP {
  height: 67%;
  margin-right: -2vw;
  margin-left: 7vw;
  background-repeat: no-repeat;
  background-size: 80% 100%;
  position: relative;
}
.small-card1-titleOP {
  width: 95%;
  height: 30%;
  display: flex;
  align-items: flex-start;
  font-family: var(--globalFont) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 2vw;

  color: var(--globalColorButton);
}
.small-card1-paraOP {
  margin-top: 0.8vw;
  width: 95%;
  flex-direction: column;
  display: flex;
  font-family: var(--serviceCardFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.87vw;
  line-height: 1.06vw;
  color: #44576a;
}
