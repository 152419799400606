* {
  margin: 0;
  padding: 0;
}

#combo-box-demo {
  height: 100%;
}

.LocationMainBox {
  padding-left: 3vw;
  display: inline-flex;
  position: absolute;
  padding-top: 0.5vw;
  outline: none !important;
}

.LocationMainBoxTitle {
  height: 50%;
  display: flex;
  justify-content: center;
  outline: none !important;

}
.LocationMainBoxTitle Select {
  height: 2vw;
  width: 100%;
  border: hidden;
  background: transparent !important;
  color: #e68d36;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 550;
  font-size: 0.87rem;
  line-height: 150%;
  outline: none !important;
  

}
.ShoppingCartImageSize{
  width: 1.5vw;
  height: 1.8vw;
}

.navLogo {
  display: inline-block;
  z-index: 999;
}

.mainBoxChild {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr ;
  column-gap: 1vw;
  height: 5.5vw;
  align-items: center;
  justify-content: space-between;
}
.navbarTitle {
  width: 16vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarTitle img {
  width: 13vw;
  height: 4vw;
}
.LoactionMainBox {
  width: 12%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.locationBoxHeader {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.locationBoxHeader p {
  color: #e68d36;
  margin-bottom: 4%;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vh;
}
.LoactionMainBoxTitle {
  height: 50%;
  display: flex;
  justify-content: flex-start;
}

.searchBar {
  position: relative;
  /* width: 60vw; */
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(136, 108, 15);
  font-size: 28px;
  font-weight: bold;
  margin-top: 2vw;

}
.searchBar input {
  height: 100%;
  width: 90%;
  border: hidden;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  padding-left: 1em;
  color: black;
}
.searchBar a {
  margin-right: 4%;
}
.searchBarImage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchBarImage img {
  padding-right: 1em;
  padding-bottom: 0.3em;
}
.navbarSideButtons {
  /* width: 74%;
  height: 60%; */
  display: flex;
  align-items: center;
}
#search::placeholder {
  color: var(--globalColorHeader);
}
.statusdiv {
  /* width: 25vw; */
  /* height: 60%; */
  display: flex;
  margin-top: 2vw;
  align-items: center;
  font-size: 18px;
  font-weight: bolder;
  color: rgb(136, 108, 15);
  /* justify-content: end; */
}
.navbarSideButtons button {
  background: transparent;
  border: hidden;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  color: #44576a;
  outline: none !important;
}


.shoppingBagButton {
  background: transparent;
  border: hidden;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  color: #44576a;
}
.profile {
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.avatar_name {
  font-size: 0.9rem;
  color: #39466c;
  margin-top: auto;
  margin-bottom: auto;

}
.dropdown {
  display: flex;
}
.nav-item :hover {
  color: darkgray;
}
.pic {
  position: relative;
  bottom: 0.3em;
  left: 0.3em;
}
.iconProfile {
  margin-right: 1em;
}
.dropdown-menu {
  text-align: initial !important;
  width: auto !important;
  margin-left: -2em;
  margin-top: 0.4em;
  color: #44576a !important;
  text-align: center;
}
.dropdown-item {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  line-height: 17px;
  margin-top: 1em;

  color: #44576a;
}
.dropdown-itemNew {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize14);
  line-height: 17px;
  margin-top: 0.8em;
  color: #44576a;
}
.dropdown-itemLogout:hover {
  cursor: pointer;
}
.dropdown-itemLogout {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  margin-left: 2vw;
  margin-bottom: 1em;
  line-height: 17px;


  color: #44576a;
}
.accountLine {
  color: #44576a;
  font-size: 10px;

  margin-left: 1em;
  margin-right: 1em;
}

.dropdown-item:hover {
  cursor: pointer;
}
.nav-link {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0.3em;
}
.loginButton:hover {
  cursor: pointer;
}
.registerButton:hover {
  cursor: pointer;
}
.accountButton:hover {
  cursor: pointer;
}
.shoppingBagButton:hover {
  cursor: pointer;
}
.accountButton {
  margin-top: 0.4em;
}
.shoppingBagButtonHref {
  margin-right: 3vw;
}

.css-1ujsas3 {
  color: #44576a !important;
}

.searchModal {
  display: content;
}

.searchModalClose {
  display: none;
}
.navbarBorderLine {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  background-color: var(--globalColorHeader);
  opacity: 0.4;
}
@media screen and (max-width: 600px) {
  .css-106c1u2-MuiBadge-badge {
    width: 4vw !important;
    height: 4vw !important;
    padding-left: 0.5vw;
    font-size: 2vw !important;
    min-width: 0vw !important;
  }
}

@media screen and (max-width: 700px) {
  .mblMenu {
    margin-top: 2em;
    margin-left: 3.2vw;
    z-index: 999;
    color: var(--globalColorHeader);
  }


  .MnavbarTitle {
    width: 10%;
    height: 100%;
  }
  .MnavbarTitle img {
    width: 8em;
    margin-top: 1.3em;
    margin-left: 1em;
    height: auto;
  }
  .mobileMainBox {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
  }
  .a {
    z-index: -1vw !important;
  }
  .mblCarts {
    z-index: 0;
    display: flex;
    padding-left: 14vw;
  }
  .shoppingBagButton {
    background: transparent;
    border: hidden;

    margin-top: 2.3em;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 0.87rem;
    color: #44576a;
  }
  .NavbarMblATage {
    margin-left: 20vw;
  }

  .searchBar {
    width: 82%;
  
    height: 2.4em;
    margin-top: 1em;
    margin-left: 8.6vw;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
  }
  .navLine {
    color: #44576a;
    font-size: 0.6em;
  }
}

.MblNavigationBody {
  display: flex;
  flex-direction: column;
}
