/* Body Padding */
body {
    padding-top: 100px; /* Adjust this value to match the height of your navbar */
  }
  
  /* Navbar Transition */
  .transition-navbar {
    position: fixed;
    width: 100%;
    z-index: 1050;
    transition: top 0.3s ease-in-out, height 0.3s ease-in-out; /* Smooth hide/show and resizing */
  }
  
  /* Navbar Hidden State */
  .hidden {
    top: -100px; /* Matches navbar height */
  }
  
  /* Navbar Visible State */
  .visible {
    top: 0;
  }
  
  /* Custom Navbar Styling */
  .custom-navbar {
    background-color: rgb(146, 49, 49) !important; /* Violet background */
    height: 100px; /* Original navbar height */
    padding: 20px 10px; /* Add spacing */
    transition: height 0.3s ease-in-out; /* Smooth height transition */
  }
  
  /* Smaller Navbar when Scrolled 100px Down */
  .custom-navbar.small {
    height: 70px; /* Smaller height */
    padding: 10px 10px; /* Adjust padding for smaller size */
  }
  
  /* Brand Styling */
  .custom-brand {
    font-size: 1.8rem;
    font-weight: bold;
    transition: font-size 0.3s ease-in-out;
    color: white !important;
  }
  
  /* Link Styling */
  .custom-link {
    font-size: 1rem !important;
    font-weight: bold !important;
    padding-right: 5% !important;
    color: white !important;
   
  }
  
  /* Input Styling */
  .custom-input {
    font-size: 1rem;
  }

  .containerBodyNavbar{
   padding-left: 9% !important;
  }

  .navbarItemsLink{
    padding-left: 30%;
  }