.smallViewButton {
  margin-left: 5%;
  position: relative;
  outline: none !important;

  top: 4.9vw;
  /* bottom: 10%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.5vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  /* padding-left: 2em;
  padding-right:2em;
  padding-top: 1em;
  padding-bottom: 1em; */
}

.smallViewButton1 {
  margin-left: -6vw;
  position: relative;
  outline: none !important;

  top: 5.5vw;
  /* bottom: 10%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.5vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  /* padding-left: 2em;
  padding-right:2em;
  padding-top: 1em;
  padding-bottom: 1em; */
}
.smallViewButtonDiagnostic{
  margin-left: -1.3vw;
  position: absolute;
  outline: none !important;

  cursor: pointer;
  /* top:46%; */
  top: 5.3vw;
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.3vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  /* padding-left: 2em;
  padding-right:2em;
  padding-top: 1em;
  padding-bottom: 1em; */
}
.smallViewButtonCashless{
  margin-left: 5%;
  outline: none !important;
  position: absolute;
  cursor: pointer;
  /* top:46%;
  2.7
   margin-top: 0.9vw;
  /* bottom: 70%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  margin-top: 8.8vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.3vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  /* padding-left: 2em;
  padding-right:2em;
  padding-top: 1em;
  padding-bottom: 1em; */
}
.largeViewButton {
  margin-left: 5%;
  outline: none !important;

  position: absolute;
  top:13.9vw;
  cursor: pointer;
  /* bottom: 70%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.3vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  /* padding-left: 2em;
  padding-right:2em;
  padding-top: 1em;
  padding-bottom: 1em; */
}

.largeViewButton1 {
  margin-left: 5%;
  cursor: pointer;
  position: relative;
  outline: none !important;

  top:5.8vw;
  /* bottom: 10%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.5vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  /* padding-left: 2em;
  padding-right:2em;
  padding-top: 1em;
  padding-bottom: 1em; */
}

@media screen and (max-width:900px) {

  .largeViewButton1 {
    margin-left: 5%;
    cursor: pointer;
    position: relative;
    outline: none !important;
    top: 4.8vw;
    /* bottom: 10%; */
    background:var(--globalColorButton);
    border-radius: 0.6vw;
    border: none;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.5vw;
    text-align: center;
    color: #ffffff;
    padding-left: 0.8vw;
    padding-right: 0.8vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}
.smallViewButtonDiagnostic {
  margin-left: -1.3vw;
  position: absolute;
  outline: none !important;
  cursor: pointer;
  /* top: 46%; */
  top: 5vw;
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.3vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.largeViewButton {
  margin-left: 5%;
  outline: none !important;
  position: absolute;
  top: 13vw;
  cursor: pointer;
  /* bottom: 70%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.3vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.smallViewButtonCashless {
  margin-left: 5%;
  outline: none !important;
  position: absolute;
  cursor: pointer;
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  margin-top: 3.2vw;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.3vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
.smallViewButton1 {
  margin-left: -6vw;
  position: relative;
  outline: none !important;
  top: 4.4vw;
  /* bottom: 10%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.5vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
.smallViewButton {
  margin-left: 5%;
  position: relative;
  outline: none !important;
  top: 4vw;
  /* bottom: 10%; */
  background:var(--globalColorButton);
  border-radius: 0.6vw;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.5vw;
  text-align: center;
  color: #ffffff;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
}