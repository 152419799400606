.small-card1DS {
  width: 18%;
  background: linear-gradient(149.59deg, #ffffff 0%, #e3e3e3 100%);
  /* box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1); */
  border-radius: 12px;
  height: 97%;
  display: flex;
  flex-direction: column;
}

.small-card1-bodyDS {
  height: 60%;
  /* background-color: rgb(0, 255, 0); */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1em;
}
.small-card-imgDS {
  height: 74%;
  background-repeat: no-repeat;
  background-size: 100% 98%;
  margin-left: 2vw;
  position: relative;
}
.small-card1-titleDS {
  width: 95%;
  /* height: 30%; */
  /* margin-top: 0.8vw; */
  display: flex;
  align-items: flex-end;
  /* background-color: aqua;
  border:1px solid black; */
  font-family: var(--globalFont) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 2vw;

  color: var(--globalColorButton);
}
.small-card1-paraDS {
  width: 95%;
  flex-direction: column;
  display: flex;
  margin-top: 0.8vw;
  font-family: var(--serviceCardFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.87vw;
  line-height: 1.06vw;
  color: #44576a;
}
