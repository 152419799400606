
.large-card1 {
  background: var(--linGra);
  border-radius: 12px;
  width: 60%;
  height: 97%;
  display: flex;
  position: relative;
}

.large-card1-text {
  width: 80%;
  height: 45%;
  margin-top: 1em;
  position: relative;
}
.large-card1-imgs {
  margin-top: -1.9vw;
  height: 20vw;
  margin-left: -7vw;
}
.large-card1-img {
  width: 58%;
  height: 78%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  /* background-color: aqua; */
  bottom: 0;
  right: 0;
}
.large-card1-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.68rem;
  font-family: var(--globalFont);
  font-style: normal;
  font-Weight: 500;
  font-size: 1.8vw;
  line-height: 2vw;
  color: var(--globalColorButton);
}

.large-card1-para {
  width: 38vw;
  margin-top: 0.8vw;
  height: 60%;
  padding-left: 0.68rem;
  /* margin-top: 1.1875em; */
  font-family: var(--serviceCardFont);
  font-style: normal;
  font-Weight: 500;
  font-size: 0.87vw;
  line-height: 1.06vw;
  color: #44576a;
  /* background-color: aqua;
border:1px solid black; */
}

.large-card1-body {
  margin-left: 2.1875em;
  margin-top: 0.125em;
  display: flex;
  justify-content: space-between;
}

.landingHeading2 {
  font-Weight: 500;
}

