* {
  margin: 0;
  padding: 0;
}
.firstPage {
  --serviceCardFont: "Lato", sans-serif;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44vw;
}
.containers {
  /* background-color: aqua; */
  /* margin-top: 10vh; */
  width: 90%;
  height: 38vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.row1 {
  height: 50%;
  display: flex;
  /* margin-left: 6vw; */
  /* background-color: brown; */
  justify-content: space-between;
}

.row2 {
  height: 50%;
  display: flex;

  /* background-color: blueviolet; */
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 500px) {
  .landingCarouselBtn {
    margin-top: 7vw;
    /* margin-left: -4.8em; */
    background: #fefeff;
    background: var(--globalColorButton);
    border-radius: 12px;
    width: 119px;
    color: white;
    margin-left: -50vw;
    height: 66px;
    outline: none;
    border: none;
    padding: 10px;
    font-size: 4vw;
  }
  .carouselMain1 {
    width: 450px;
  }

  .Imagee {
    height: 80%;
    /* width: 70%; */
    /* background-color: aqua; */
    border-radius: 20px;
    /* box-shadow: 0px 0px 4px 4px rgb(0 0 0 / 10%); */
    display: flex;
    flex-direction: column;
    /* margin-bottom: 2em; */
    /* margin-top: -7em; */
    justify-content: center;
    align-items: center;
  }

  .ProductPageMainHeading {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #e68d36;
  }

  .ProductPageSubHeading {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #1d2334;
    margin-left: 5.38vw;
    margin-right: 5.38vw;
  }

  .productCarousel {
    min-height: 20em;
    max-height: 21em;
  }

  .carouselImageMbl {
    min-width: 15.06em;
    min-height: 8.43em;
    margin-left: 0em;
    margin-top: 5.6em;

    /* transform: scaleX(-1); */
  }

.carouselsNameMbl {
  /* font-size: 1.5em; */ 
  width: 82vw;
  font-weight: 500;
  line-height: 28.44px;
  /* margin-left: -3em; */
  position: absolute;
  margin-top: -41vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 5.7vw;
  line-height: 28px;
  /* text-align: center; */
  color: #f8f8f8;
 
}

.carouselPriceMbl {
  margin-top: 8vw;
  /* margin-left: 2em; */
  width: 81vw;
  font-size: 0.875em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 3.3vw;
  line-height: 17px;
  color: #f8f8f8;
}

  .carouselPriceMbl2 {
    /* margin-top: 4em; */
    margin-left: 2em;
    width: 15.75em;
    font-size: 0.875em;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #44576a;
  }

  .ProductsOffCards {
    margin-top: 4.125em;
    display: flex;
    flex-direction: column;
  }
  .LandingtextMobile {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
  }
}

.hloa {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120%;
  z-index: 9990;
  justify-content: center;
}
