.gridFacilities{
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
}

.FacilitiesContainer{
    padding-left: 4vw;
    padding-right: 4vw;
}

.amenity{
    display: inherit;
    align-items: center;
    text-align: center;
    height: 4vw;
    /* background-color: red; */
    position: relative;
}

.amenity::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background: grey;
    right: -9px
  }