

.landingPageContentBody {
  padding-top: 8vw;
  padding-left: 4vw;
}

.landingSecondPageHeading {
  font-size: 28px;
  color: rgb(136, 108, 15);
  font-weight: bold;
  align-items: center;
  font-family: "Montserrat";
  
  /* text-align: center; */
}

.landingSecondPageSubHeading1 {
  font-size: 18px;
  color: rgb(136, 108, 15);
  font-weight: bold;
  font-family: "Montserrat";

  align-items: left;

  text-align: left;
}

.landingSecondPageContent {
  font-size: 18px;
  padding-top: 3vw;
  font-family: "Montserrat";


  color: black;
  padding-right: 0vw;
  text-align: left;
}

.landingSecondPageSubHeading3 {
  font-size: 14px;
  color: rgb(136, 108, 15);
  font-weight: bold;
  align-items: left;
  text-align: left;
  padding-top: 2vw;
  font-family: "Montserrat";
padding-right: 4vw;
  padding-bottom: 20vw;
}

.landingSecondPageMobile .landingPageContentBody {
  padding-right: 6vw;
  padding-top: 8vw;
  padding-left: 6vw;
}

.landingSecondPageDesktop .landingPageContentBody {
 
  DISPLAY: FLEX;
  padding-top: 4vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 4vw;
  padding-right: 4vw;
 
}

.landingPageImages {

}

 .img {
  background-image: url("https://ladakh-upload.s3.ap-south-1.amazonaws.com/Mobile/IMG_2419.webp");

  background-repeat: no-repeat;
  background-size: cover;
}
 .img2 {
  background-image: url("https://ladakh-upload.s3.ap-south-1.amazonaws.com/Mobile/IMG_2414.webp");

  background-repeat: no-repeat;
  background-size: cover;
}


@media (max-width: 600px) {
  .landingPageImg1 {
    z-index: 99;
    width: 76vw;
    height: 96vw;
    margin-top: 4vw;
    margin-left: 16vw;
  }
  .landingPageImages{
    padding-bottom: 10vw;
  }
  .landingPageImg2 {
    z-index: 99;
    position: absolute;
    width: 35vw;
    height: 52vw;
    margin-left: 6vw;
    margin-top: -72vw;
  }

  .img {
    background-image: url("https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/IMG_2355.webp");
  
    background-repeat: no-repeat;
    background-size: cover;
  }
  .landingSecondPageMobile {
    background-color: #faf8f5;
   
  }

  .landingSecondPageMobile .img {
    background-image: url("https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/IMG_2355.webp");

    background-repeat: no-repeat;
    background-size: cover;
  }

  .landingPageImg1Mobile {
    z-index: 99;
    width: 81vw;
    height: 96vw;
    margin-top: 4vw;
    margin-left: 16vw;
  }

  .landingPageImg2Mobile {
    z-index: 99;
    position: absolute;
    width: 35vw;
    height: 52vw;
    margin-left: 2vw;
    margin-top: -72vw;
  }
}
.landingSecondPageDesktop{
  background-color: #faf8f5;
  /* padding-bottom: 6vw; */
  /* display: grid;
  grid-template-columns: 1fr 1fr; */

}

@media (min-width: 601px) {
  .landingSecondPageDesktop .landingPageImages {
  
  }

  .landingSecondPageDesktop .img {
 
  }

  .landingPageImg1 {
    z-index: 99;
    width: 32vw;
    height: 43vw;
    margin-top: 4vw;
    margin-left: 8vw;
  }

  .landingPageImg2 {
    z-index: 99;
    width: 20vw;
    height: 30vw;
    margin-top: -37vw;
  }
}
