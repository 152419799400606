.landing-page {
    position: relative;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensures the video stays in the background */
  }
  
  .content {
    position: relative;
    z-index: 1; /* Ensures content appears on top of the video */
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 20vh; /* Adjust text position vertically */
  }
  
  h1 {
    font-size: 3rem;
  }
  
  p {
    font-size: 1.5rem;
  }
  