/* .imageAvtar{
    border-radius: 50%;
}

.carousel .slide img {
    width: 20% !important;
    border-radius: 60% !important;
} */

/* TestimonialSlider.css */
.testimonial-card .card-up {
    height: 120px;
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .testimonial-card .avatar {
    width: 110px;
    margin-top: -60px;
    overflow: hidden;
    border: 3px solid #fff;
    border-radius: 50%;
  }


 
  
  .slick-slide {
    padding: 0 10px; 
    margin-bottom: 2vw;
  }

  .text-center1{
    font-family: "Montserrat";
    color: rgb(136, 108, 15);
    text-align: left !important;
   padding-left: 1vw;
    margin-bottom: 2em;
    /* padding-top: 4vw; */
    display: flex;
    flex-direction: column;
    align-items: center;



  }

  .text-center1 .textHeadingTestimonal{
    font-family: "Montserrat";
    color: black !important;
    font-size: 18px;

  }

  .text-center1 .ladakhColor{
    font-family: "Montserrat";
    color: rgb(136, 108, 15);


  }
  .justify-content-center{
    justify-content: left !important;
    margin-left: 1vw;

  }

  