.pageNotfoundMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageNotfoundMainPara1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #44576a;
  margin-top: 2em;
  margin-bottom: 2em;
}
.pageNotfoundMainPara2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #44576a;
  margin-top: 2em;
  margin-bottom: 2em;
}
