.backgroundImageBody{
    height: 50%;
    width: 100%;
}
.background-container {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background-image: url('../../../assets/bike1.jpeg');
    background-size: 100% 100%;
    background-position: center top;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    justify-content: center;
    /* You can add more styling properties as needed */
  }
  
  .bikeCalanderBody{
    background-color: white;
    border-radius: 16px;
    margin-right: 55vw;


  }
.bikeCalanderBodyContainer{
    padding: 2vw;
}

  .bikeCalanderBodyHeading{
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
 
 
  }

  .bikeCalanderBodySubheading{
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
 margin-top: 2vw;
 
  }

  .rentalDate{
    display: flex;
    padding-top: 0.5vw;
    padding-left: 1VW;
    padding-bottom: 0.5vw;
    padding-right: 1vw;
  }

  .rentalDateBody{
    margin-top: 1vw;
    display: flex;
  }

  .rentalSubmitButton{
    outline: none !important;
    border: none !important;
    background-color: rgb(243, 243, 107);
    margin-top: 3vw;
    padding-top: 1vw;
    width: 100%;
    padding-bottom: 1vw;
    border-radius: 6px;
  }
  