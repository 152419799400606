.roomAndSuitsBody{
    margin-left: 5vw;
    margin-right: 5vw;
}
.roomsBodySuit{
    background-color: #faf8f5;
    /* padding-bottom: 10vw; */

}

.RoomAndSuitsImage {
               
    width: 100vw;
    margin-top: 5vw;
    height: 17vw;
    border-radius: 12px;
  }

  .roomAndSuitsContentBox{
    display: flex;
    /* border-radius: 12px; */
    margin-top: -12vw;
    margin-left: 4vw;
    margin-right: 22vw;
    height: auto;
    /* padding-right: 8vw; */
    width: 90%;
    background-color: white;
    font-family: "Montserrat";
  }

  .roomAndSuitsContentBox1{
    display: flex;
    /* border-radius: 12px; */
    margin-top: -12vw;
    margin-left: 4vw;
    margin-right: 22vw;
    height: auto;
    /* padding-right: 8vw; */
    width: 56%;
    background-color: white;
    font-family: "Montserrat";
  }

  .roomAndSuitsItem{
    display: flex;
    margin-top: 3.4vw;
  }
  .roomAndSuitsItem1{
    margin-left: 3vw;
    display: flex;
  }

  .roomAndSuitsItem11{
    /* margin-left: 3vw; */
    display: flex;
  }
  .hi ::before{

  }
.text{
    /* margin-top: 1vw; */
    margin-left: 0.6vw;
}

.reserveRoom{
  background-color: #e68d36;
  outline: none;
  border: none;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 12px;
}