@import url(https://fonts.googleapis.com/css?family=Raleway:400);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
figure.snip1193 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  /* min-width: 220px; */
  /* max-width: 310px; */
  /* max-height: 220px; */
  /* width: 100%; */
  background: #000000;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.roomBody2{
  display: flex;
  flex-direction: column;
  align-items: center;
}
figure.snip1193 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
figure.snip1193 div {
  opacity: 1;
  width: 100%;
  display: block;
}
figure.snip1193 figcaption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 10px 12px 5px;
  position: absolute;
}
figure.snip1193 h4,
figure.snip1193 i {
  color: #ffffff;
}
figure.snip1193 h4 {
  font-weight: 400;
  font-family: "Montserrat";
  left: 0;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
figure.snip1193 i {
  font-size: 22px;
}
figure.snip1193 a {
  text-decoration: none;
}
figure.snip1193  .hoverContentRoom,
figure.snip1193 .bottom-left,
figure.snip1193 .bottom-right {
  position: absolute;
  bottom: 5px;
  opacity: 0;
}

figure.snip1193  .hoverContentRoom
{
  font-family: "Montserrat";

  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
figure.snip1193 .bottom-left {
  left: 5px;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
figure.snip1193 .bottom-right {
  right: 5px;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
figure.snip1193:hover div,
figure.snip1193.hover div {
  opacity: 0.6;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
figure.snip1193:hover  figcaption .hoverContentRoom,
figure.snip1193:hover figcaption .bottom-left,
figure.snip1193.hover figcaption .bottom-left,
figure.snip1193:hover figcaption .bottom-right,
figure.snip1193.hover figcaption .bottom-right {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.roomImageBox{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.25vw;
  
}

.roomHeading1{
  font-size: 12px;
}

.roomHeading2{
  color: rgb(136, 108, 15);
  /* font-size: 12px; */
}

.roomBody{
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  font-family: "Montserrat";
  background-color: #faf8f5;

}

.hoverContentRoom{

}

.viewMoreRoom1{
  display: flex;
  justify-content: end;
  margin-right: 3vw;
  /* margin-top: 8vw; */
}

.viewMoreRoom1 :hover{
  font-size: 1.1vw;
  font-weight: bold;
}

.viewMoreRoom{
  display: flex;
  justify-content: end;
  margin-right: 3vw;
  margin-top: 8vw;
}



.viewMoreRoom :hover{
  font-size: 4vw;
  font-weight: bold;
}