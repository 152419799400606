.large-card {
  background: linear-gradient(149.59deg, #ffffff 0%, #e3e3e3 100%);
  /* box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1); */
  border-radius: 0.75em;
  width: 60%;
  height: 97%;
  display: flex;
}
.large-card-img {
  width: 10%;
  height: 29%;
  position: absolute;
  margin-left: 33vw;
  display: flex;
  margin-top: 9.3vw;
  background-repeat: no-repeat;
  background-size: 16vw 9vw;
}

.large-card-img1 {
  width: 15%;
  position: absolute;
  height: 30%;
  margin-left: 37vw;
  display: flex;
  margin-top: -1vw;
  background-repeat: no-repeat;
  background-size: 14vw 12vw;
}

.large-card-img2 {
  position: absolute;
  width: 10%;
  height: 20%;
  margin-left: 43vw;
  display: flex;
  margin-top: 9.3vw;
  background-repeat: no-repeat;
  background-size: 10vw 9vw;
}

.large-card-body {
  width: 40%;
  display: flex;
  margin-top: 1em;
  position: relative;
}

.large-card-text {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
}

.largeCardTitle {
  display: flex;
  align-items: flex-start;
  padding-left: 0.68rem;
  font-family: var(--globalFont) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 2vw;
  color: var(--globalColorButton);
}

.largeCardPara {
  margin-top: 1.8vw;
  font-size: 0.87vw;
  width: 29vw;
  color: #44576a;
  font-weight: 400;
  padding-left: 0.68rem;
  font-family: var(--serviceCardFont);
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  line-height: 1.06vw;
}
.largeCardParaRisk {
  height: 34px;
  font-size: 0.87rem;
  width: 16vw;
  margin-top: 24px;
  margin-left: 26px;
  font-weight: 400;
  font-family: var(--serviceCardFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #44576a;
}
.large-view-button {
  margin-top: 5.125em;
  background: var(--globalColorButton);
  border-radius: 12px;
  border: none;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding-left: 1.4375em;
  padding-right: 1.4375em;
  padding-top: 0.8125em;
  padding-bottom: 0.8125em;
}
