.PackageDetailContainer {
  margin-left: 6.5vw;
  margin-top: 1vw;
}

.planBlockk {
  display: flex;
  justify-content: center;
  width: 100%;
}

.CareerImageMobile {
  position: absolute;
  margin-top: -3vw;
}

.About_PackageMainHeading {
  font-weight: 500;
  font-size: 1.7vw;
  color: #44576a;
}

.About_PackageSubHeading {
  font-weight: 400;
  font-size: 1.2vw;
  color: #44576a;
  margin-top: 1.5vw;
}

.WhyToBookWithUsContainer {
  margin-top: 2vw;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 5.1vw;
  /* background-color: #2196F3; */
  padding: 0.7vw;
  margin-right: 6.69vw;
  margin-bottom: 2vw;
}

.WhyToBookWithUsContainer>div {
  background: #ffffff;
  border: 1px solid #e8e9e9;
  border-radius: 12px;
  text-align: center;
  height: 8.8vw;
  /* padding: 10px 0; */
  font-size: 1vw;
}

.WhyToBookWithUshead {
  font-weight: 400;
  font-size: 0.88vw;
  width: 12vw;
  color: #44576a;
  margin-top: 4.5vw;
}

.PrimeComprehensive {
  font-family: var(--globalFont);
  font-style: normal;
  margin-left: 6.6vw;
  padding-top: 2vw;
  font-weight: 500;
  font-size: 1.7vw;
  color: var(--globalColorMainHeading);
  text-transform: capitalize;
}

.PrimeComprehensive_Button {
  margin-right: 4vw;
  background: var(--globalColorHeader);
  border-radius: 12px;
  color: white;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border: none;
  outline: none !important;
  cursor: pointer;
  margin-top: 4vw;
  font-size: 1vw;
}

.PrimeComprehensive_ButtonAdded {
  margin-right: 5vw;
  background: #0A7B9E;
  border-radius: 12px;
  color: white;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border: none;
  outline: none !important;
  cursor: pointer;
  margin-top: 4vw;
}

.PrimeComprehensiveFlex {
  display: flex;
  justify-content: space-between;
}

.PrimeComprehensivePrice {
  font-weight: 100;
  font-size: 1.5vw;
  color: #44576a;
  margin-left: 6.6vw;
}

.PrimeComprehensivePriceDisplay {
  display: flex;
  margin-top: -2vw;
}

.PrimeComprehensiveSecondPrice {
  font-weight: 500;
  font-size: 1.8vw;
  color: #f94a4a;
  margin-left: 1vw;
  text-decoration: line-through;
}

.HomeSampleContainer {
  background: #ccd4ec;
  border-radius: 6px;
  margin-left: 6.6vw;
  margin-right: 6.6vw;
  margin-top: 2.2vw;
}

.HomeSampleContainerPara {
  color: #44576a;
  font-weight: 400;
  font-size: 0.7vw;
}

.line-1 {
  height: 1px;
  background: #44576a;
  margin: 1vw;
}

.HomeSampleContainerPara1 {
  margin-top: -1.2vw;

  margin-left: 2vw;
  padding-top: 1vw;
}

.HomeSampleContainerPara2 {
  text-align: center;
  padding-bottom: 0.4vw;
}

.Hme {
  font-size: 1vw !important;
  position: absolute !important;
  left: 8.5vw !important;
  right: 22vw !important;
}

.TestsGrid {
  width: 43vw;

  background: var(--globalColorHeader);
  border-radius: 12px;
  border: none;
  outline: none !important;
  padding: 1.4vw;
  color: #ffffff;
}

.ParamterCountDisplay {
  display: flex;
}

.About_PackageMainHeadingLabGrid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1.1vw;
  font-size: 1vw;
  margin-right: 6.69vw;
  margin-top: 3vw;
}

.About_PackageMainHeadingLabGrid1 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1.1vw;
  font-size: 1vw;
  margin-right: 6.69vw;
  margin-top: 3vw;
}

.HomeSampleContainerLongParaText {
  padding-bottom: 1vw;
  padding-left: 1vw;
  font-weight: 500;
  font-size: 1vw;
  color: #44576a;
}
.dropdownImage {
  margin-top: 0.5em;
  position: absolute;
  left: 68vw;
  bottom: 2vw;
  cursor: pointer;
  width: 4vw;
}

.detailTestHeadingImage {
  /* position: absolute; */
  width: 100%;
  margin-top: 1.2vw;
  height: 17vw;
}

/* .css-i4bv87-MuiSvgIcon-root {
  font-size: 1vw !important;
} */

.dotPackage {
  width: 0.9vw;
  height: 0.9vw;
  margin-top: 0.4vw;
}

.ParamterCountDisplayData {
  padding-left: 1vw;
}

@media screen and (max-width: 600px) {
  .PrimeComprehensive_ButtonAdded {
    margin-right: 5vw;
    background: var(--globalColorButton);
    border-radius: 12px;
    color: white;
    padding-left: 35vw;
    padding-right: 35vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    margin-left: 1vw;
    margin-bottom: 5vw;
    border: none;
    outline: none !important;
    cursor: pointer;
    margin-top: 4vw;
  }

  .PrimeComprehensive {
    font-size: 18px;
  }

  .PrimeComprehensivePrice {
    font-size: 20px;
    margin-top: 3vw;
  }

  .HomeSampleContainer {
    background: #ccd4ec;
    border-radius: 6px;
    margin-left: 6.6vw;
    margin-right: 6.6vw;
    width: 57vw;
    margin-top: 2.2vw;
  }

  .HomeSampleContainerLongParaText {
    font-size: 18px;
  }

  .HomeSampleContainerLongParaTextM {
    background: var(--globalColorHeader);
    border-radius: 6px;
    padding-left: 3vw;
    margin-left: 6vw;
    margin-right: 7vw;
    margin-top: 3vw;
  }

  .HomeSampleContainerParaM {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #ffffff;
    padding: 1em;
  }

  .PackageDetailContainer {
    margin-top: 3vw;
  }

  .About_PackageMainHeading {
    font-size: 4.8vw;
    font-weight: 500;
  }

  .About_PackageSubHeading {
    font-size: 3.3vw;
    /* margin-left: 1vw; */
    margin-right: 5vw;
    /* width: 90vw; */
    margin-bottom: 1vw;
  }

  .PrimeComprehensive_Button {
    width: 87vw;

    padding-top: 2vw;
    padding-bottom: 2vw;
    margin-bottom: 5vw;
    border: none;
    outline: none !important;
    cursor: pointer;
    margin-top: 4vw;
    font-size: 3vw;
  }

  .package-card-head {
    position: relative;
    display: inline-block;
    width: 79vw;
    /* margin-right: 2%; */
    margin-top: 7%;
    padding-left: 3vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    background-color: var(--globalColorButton);
    border-radius: 12px;
    font-size: 12px;
  }

  .package-card-headnew {
    position: relative;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    width: 79vw;
    /* margin-right: 4vw; */
    margin-top: 5vw;
    background-color: #ffffff;
    border: 1px solid var(--globalColorButton);
    border-radius: 12px;
    padding-left: 3vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--globalColorButton);
  }

  .dropdownImage {
    margin-top: 0.5em;
    position: absolute;
    left: 68vw;
    bottom: 2vw;
    cursor: pointer;
    width: 4vw;
  }

  .Collapsiblen1 {
    font-size: 2.8vw;
  }
  .CareerHeadingImagePackage{
    width: 100%;
    /* margin-top: 1.2vw; */
  
    /* height: 19vw; */
  }
}