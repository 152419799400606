.allPackagesGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4vw;
    gap: 2vw;
}
.bikeAvailable{
    display: flex;
    justify-content: center;
}
.bikePrice{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.allPackagesContentBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2vw;
    padding-bottom: -1vw;
    padding-right: 4vw;
    padding-left: 4vw;
}
.allPackagesHeading1{
    font-weight: bold;
    display: flex;
    align-items: center;
}
.allPackagesHeading2{
    font-weight: bold;
    display: flex;
    align-items: center;
}

.bookingBlock{
    display: flex;
    padding-left: 4vw;
    padding-right: 4vw;
    display: flex;
    margin-top: -2vw;
    /* flex-direction: column; */
    justify-content: space-between;
    
}
.bookingButtonBlock{
    display: flex;
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    /* margin-top: -2vw; */
    /* flex-direction: column; */
    justify-content: space-between;
    padding-bottom: 2vw;
    
}
.bookingButtonBike{
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: yellow;
    color: white;
    border-radius: 8px;
    outline: none !important;
    border: none !important;

}