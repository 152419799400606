.small-card {
  background: linear-gradient(149.59deg, #ffffff 0%, #e3e3e3 100%);
  /* box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1); */
  border-radius: 12px;
  /* margin-left: 1vw; */
  width: 18%;
  border-radius: 12px;
  height: 97%;
  position: relative;
  /* background-color: yellow; */
}

.small-card-body {
  /* text-align: left; */
  width: 95%;
  height: 45%;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-left: 1.3125em; */
  /* margin-top: 1.5625em; */
  /* background-color: #44576a; */
}

.small-card-title {
  width: 95%;
  display: flex;
  align-items: flex-start;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  padding-left: 0.68rem;
  color: #44576a;
}

.small-card-para {
  width: 95%;
  flex-direction: column;
  margin-top: 0.8vw;
  padding-left: 0.68rem;
  display: flex;
  font-family: var(--serviceCardFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.87vw;
  line-height: 1.06vw;
  color: #44576a;
}
