#about {
    background: #2c3e50;
    padding: 120px 0;
  }
  
  #about h1 {
    color: #fff;
  }
  
  #about p {
    color: #fff;
  }
  .head {
    margin-bottom: 60px;
  }
  
  h1 {
    font-size: 60px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .head-border-grey {
    width: 20%;
    height: 1px;
  }
  
  .skills {
    padding-top: 5px;
    padding-bottom: 20px;
  }
  
  #skillbar {
    list-style: none;
    width: auto;
    margin: 5px auto 0;
    position: relative;
    padding: 5px 0;
  }
  
  #skillbar li {
    margin-top: 35px;
    margin-bottom: 15px;
    background: #dcd5d5;
    height: 10px;
  }
  
  #skillbar li p {
    padding-top: 15px;
    text-align: left;
    margin-left: 2px;
    font-size: 12px;
  }
  
  .skillbar {
    height: 6px;
    margin: 2px 2px;
    position: absolute;
    background-color: #e67e22;
  }
  
  .skillbar.html {
    width: 95%;
  }
  
  .skillbar.css {
    width: 90%;
  }
  
  .skillbar.javascript {
    width: 55%;
  }
  
  .skillbar.php {
    width: 65%;
  }
  
  .skillbar.photoshop {
    width: 85%;
  }
  
