.backgroundImageBody {
    /* height: 100vh;
    width: 110vw; */
  }
  
  .img-body {
    height: 100vh;
    width: 110vw;
  }
  
  .img-body img {
    height: 100%;
    width: 100%;
  }
  

  .backGroundPink{
    background-color: #faf8f5;
  }