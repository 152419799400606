.LandingServicesMainContainer {
  background-color: white;
  padding-bottom: 6rem;
}

.LandingServicesContainer {
  display: flex;
  margin-left: 4vw;
  /* justify-content: center; */
  /* font-size: var(--globalFontSize28); */
  color: var(--globalColorMainHeading);
font-family: "Montserrat";
color: rgb(136, 108, 15);
  padding-top: 4rem;
}

.LandingServicesSubContainer {
  display: flex;
  justify-content: center;
  color: var(--globalColorSubMainHeading);
  margin: 3vw 4vw 3vw 4vw;
  /* text-align: center; */
font-family: "Montserrat";

  white-space: pre-wrap;
  font-size: var(--globalFontSize20);
}

.ServicesOfferedgridcontainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
}

.ServiceOfferedgriditem {
  position: relative;
}

.ServicesOfferedCirclesecond {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  border: 2px solid rgb(136, 108, 15);;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0 auto;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.ServicesOfferedCircleFirst {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--globalColorHeader);
  overflow: hidden;
}

.inner-circle {
  width: 0;
  height: 0;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: width 0.5s, height 0.5s, opacity 0.5s;
}

.ServicesOfferedCirclesecond.hovered {
  background-color: #a44949;
  font-size: 1vw;
  font-weight: bold;
  color: rgb(136, 108, 15);;
}

.ServicesOfferedCirclesecond.hovered .inner-circle {
  width: 7.8vw;
  height: 7.8vw;
  opacity: 1;
  z-index: 111;
}

.ServicesOfferedCircleFirstImage {
  z-index: 222;
  color: rgb(136, 108, 15);;
  font-family: "Montserrat";

}
.ServicesOfferedCircleFirstImage .p {
  margin-bottom: 0vw !important;
  z-index: 222;
  color: rgb(136, 108, 15);;
  font-family: "Montserrat";
}

.ServicesOfferedCirclesecondContainer {
  position: relative;
  overflow: hidden;
}

.ServicesOfferedCirclesecondContainer .ServicesTextDisplay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(232, 230, 228, 0.85);
  transition: height 0.3s ease-in-out;
  cursor: pointer;
}

.ServicesOfferedCirclesecondContainer .Services-Hover-Text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Montserrat";
  display: flex;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: 500;
  padding: 10px;
  color: rgb(136, 108, 15);;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  /* background-color: black; */
  justify-content: center;
  font-size: var(--globalFontSize20);
}

.ServicesOfferedCirclesecondContainer:hover .ServicesTextDisplay {
  height: 100%;
}

.ServicesOfferedCirclesecondContainer:hover .Services-Hover-Text {
  opacity: 1;
  /* text-transform: uppercase; */
  font-family: "Montserrat";
  cursor: pointer;
}

.ServicesIMageContainer {
  width: 24vw;
  height: 26vw;
  display: block;
}

@media screen and (max-width: 600px) {
  .LandingServicesContainer {
    font-size: var(--globalFontSizeMobile20);
  }

  .LandingServicesSubContainer {
    font-size: var(--globalFontSizeMobile16);
    margin: 1rem;
  }

  .ServicesOfferedgridcontainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }

  .ServicesIMageContainer {
    width: 95vw;
    margin: auto;
    background: rgba(232, 230, 228, 0.85);
  }

  .ServicesOfferedCirclesecondContainer .Services-Hover-Text {
    font-size: 1.25rem;
  }

  .ServicesOfferedCirclesecondMobile {
    width: 36vw;
    height: 36vw;
    border-radius: 50%;
    overflow: hidden;
    background-color: #886c0f;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    margin: 0 auto;
    position: absolute;
    color: white;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .ServicesOfferedCircleFirstMobile {
    width: 28vw;
    height: 28vw;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--globalColorHeader);
    margin: 0 auto;
    position: absolute;
    top: 100%;
    left: 50%;
  font-family: "Montserrat";

    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: var(--globalColorHeader);
    overflow: hidden;
  }

  .ServicesOfferedCircleFirstImage {
    max-width: 13vw;
  font-family: "Montserrat";

  }

  .ImageContainer {
    position: relative;
  }

  .ServicesIMageContainer {
    width: 100%;
    height: auto;
    display: block;
  }

  .ServiceOfferedgriditem {
    touch-action: manipulation;
  }

  .ServicesIMageContainerContainer {
    position: relative;
  }

  .ServicesTextDisplay {
    text-align: center;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color:white;
    position: absolute;
    top: 50%;
    left: 50%;
  font-family: "Montserrat";

    transform: translate(-50%, -50%);
    width: 100%;
    text-transform: uppercase;
    z-index: 1;
    font-size: var(--globalFontSizeMobile16);
  }

  .ImageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(111, 111, 111, 0.5); */
  }

  .ServicesOfferedCircleFirstImageMobileK {
    width: 15vw;
  font-family: "Montserrat";

  }
}
