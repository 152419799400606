/* .splash {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black; 
  }

  .splashHeading{
    color: white;

  }

  .splashSubHeading{
    color: white;
  }
  
   */

   .splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; /* You can adjust the background color */
  }
  
  .loader {
    text-align: center;
  }
  
  .splash-content {
    text-align: center;
    margin-top: 20px; /* Adjust spacing */
  }
  