* {
  margin: 0;
  padding: 0;
}
.mainTermsAndConditions {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* margin-top: 1.5em; */
}
.termsAndConditionsTop {
  display: flex;
  justify-content: center;
}
.FooterBackgroundImgArc {
  position: absolute;
}
.FooterBackgroundImage {
  position: absolute;
}
.FooterImage {
  position: relative;
  top: 1.5em;
}
.termsAndConditionsBottom {
  font-family: var(--globalFont);
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.termsAndConditionsBottom ol {
  width: 100%;
}
.termsAndConditionsBottom li {
  padding-left: 3.68em;
  padding-right: 5.8em;

  font-family: var(--globalFont);

  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  color: #292828;
  display: list-item;
  list-style-type: decimal;
  list-style-position: inside;
  text-indent: -1em;
}

.contentTermsAndConditions {
  margin-left: 5.12em;
  margin-right: 5.12em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  padding-top: 2em;
  z-index: 1;
}
.contentTermsAndConditionsHeading {
  padding-left: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 800;
  font-size: 2.5em;
  line-height: 1.5em;
  color: #2d2424;
}
.contentTermsAndConditionsBottomHeading {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.5em;
  padding-left: 1.8em;

  /* padding-bottom: 1em; */
  padding-top: 1em;
  /* padding-bottom: 0.1em; */
  color: #292828;
}
.bottomPara {
  padding-bottom: 4.3em;
}
.contentTermsAndConditions p {
  padding-left: 2.68em;
  /* padding-top: 1em; */
  padding-top: 0.3em;
  padding-right: 5.68em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  color: #292828;
}
.bottomPara a:hover {
  font-weight: bold;
}
.includedRedirect a:hover {
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .termsAndConditionsBottom li {
    padding-left: 2em;
    padding-right: 2em;
    display: list-item;
    list-style-type: decimal;
    list-style-position: inside;
  }
  .contentTermsAndConditions p {
    padding-left: 1em;
    padding-right: 2em;
  }
  .contentTermsAndConditions {
    margin-left: 1.7em;
    margin-right: 1.7em;
  }
  .contentTermsAndConditionsHeading {
    padding-left: 0.4em;
  }
  .contentTermsAndConditionsBottomHeading {
    padding-left: 0.65em;
    padding-right: 2.8em;
  }
  .FooterBackgroundImgArc {
    width: 100%;
    height: 60%;
  }
  .FooterBackgroundImage {
    width: 90%;
    height: 30%;
  }
  .FooterImage {
    width: 100%;
    height: 100%;
  }
}
