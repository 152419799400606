.galleryCarouselBody{
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 4vw;
    padding-bottom: 1vw;
}
.serviceText{
    font-size: 1vw;
    color: grey;
}

.daysPackageIcon{
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    justify-content: space-between;
}

.pricePackageIcon{
    margin-right: 1vw;
  

}

.priceBlock{
    /* margin-top: -2vw; */
}

.realprice{
    font-size: 24px;
    color: red;
    font-weight: bold;
    /* display: flex; */

}