.allPackagesGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4vw;
    gap: 2vw;
}
.allPackagesGridMbl{
 
    padding: 4vw;
    gap: 2vw;

}
.allPackagesContentBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2vw;
    padding-bottom: -1vw;
    padding-right: 4vw;
    padding-left: 4vw;
}
.allPackagesHeading1{
    font-weight: bold;
    display: flex;
    align-items: center;
}
.allPackagesHeading2{
    font-weight: bold;
    display: flex;
    align-items: center;
}