/* FullPageCarousel.css */

.carousel-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  
  .carousel-slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-image 1s ease-in-out;
  }
  
  .carousel-content {
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    padding: 20px;
    border-radius: 10px;
  }
  
  .carousel-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .carousel-content p {
    font-size: 1.5rem;
  }
  