.SearchInputModalMainContainer {
  position: absolute;
  height: -moz-fit-content;
  height: fit-content;
  left: 0px;
  padding-top: 0px;
  top: 6.6vh;
  padding-bottom: 1em;
  margin-bottom: 0px;
  overflow: hidden;
  z-index: 100;
  background-color: white;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #e68d36;
  box-shadow: 0px 0px 2px 2px rgb(230 141 54 / 23%);
  border-radius: 0px 0px 12px 12px;
  width: 44.7vw;
}

.SearchModalInputMainSubText {
  color: #44576a;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.625em;
  line-height: 150%;
  margin-top: 1vw;
  margin-left: 1vw;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.SearchInputModalMainContainerMobile {
  position: absolute;
  margin-top: 2vh;
  left: 0px;
  height: fit-content;
  padding-top: 0px;
  /* top: 12.5vh; */
  padding-bottom: 1em;
  margin-bottom: 0px;
  overflow: hidden;
  /* margin-left: 10vw; */
  z-index: 1001;
  background-color: white;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #e68d36;
  box-shadow: 0px 0px 2px 2px rgb(230 141 54 / 23%);
  border-radius: 0px 0px 12px 12px;
  width: 80vw;
}