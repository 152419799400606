.tabName {
  color: #1d2334 !important;
  border: none !important;
  outline: none !important ;
  font-weight: 600 !important;
  font-size: var(--globalFontSize16) !important;
  padding-left: 2vw !important;
}
.LocationNavigationDropDownImage {
  width: 1.222vw;
  height: 1.33vw;
  margin-top: 0.4vw;
}
.tabNameTrue {
  color: var(--globalColorHeader) !important;
  border: none !important;
  font-weight: 600 !important;
  outline: none !important ;
  padding-left: 2vw !important;
  font-size: var(--globalFontSize16) !important;
  

}

.css-1wf8b0h-MuiTabs-flexContainer {
  align-items: baseline;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
}

.BoxForPopper {
  display: block;
  flex-wrap: wrap;
  margin-top: 0.4em;
  margin-left: 18vw;
  background: radial-gradient(100% 1042.5% at 0% 100%,
      #e9ebf1 0%,
      #ffffff 19.79%,
      #ffffff 37.5%,
      #ffffff 53.12%,
      #ffffff 67.71%,
      #ffffff 86.46%,
      #d9deeb 100%);
  box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.BoxForPopper2 {
  display: block;
  flex-wrap: wrap;
  margin-top: 0.4em;
  width: 49.3vw;
  background: radial-gradient(100% 1042.5% at 0% 100%,
      #e9ebf1 0%,
      #ffffff 19.79%,
      #ffffff 37.5%,
      #ffffff 53.12%,
      #ffffff 67.71%,
      #ffffff 86.46%,
      #d9deeb 100%);
  box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  height: 11vw;
}

.NavbarImages {
  width: 9.5vw;
  height: 5.9vw;
}

.NavbarNames {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #44576a;
  z-index: 9999;
}

.NavbarGridContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.NavbarGridContaineropt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  padding-top: 3vw;
  padding-left: 42vw;
  grid-gap: 10px;
}

.NavbarGridContaineropt2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  padding-top: 3vw;
  padding-left: 24.5vw;
  grid-gap: 10px;
}

.arrowImage {
  margin-right: 0.5em;
  height: 0.7vw;
  width: 0.7vw;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: white !important;
}

.css-ttwr4n {
  background-color: white !important;
}

.NavigationDiscountSection {
  display: flex;
  font-size: var(--globalFontSize14);
  align-items: center;
}

.NavigationDiscountSectionButton {
  background-color: var(--globalColorHeader);
  border-radius: 12px;
  font-size: var(--globalFontSize14);
  padding: 0.5vw 1vw 0.5vw 1vw;
  margin-left: 1vw;
  color: #ffffff;
}
