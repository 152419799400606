.backgroundImageBody {
  height: 30%;
  width: 100%;
  /* background-color: black; */
}

.landingPageHeading1{
  font-size: 2vw;
  font-family:"Montserrat";
}
.landingPageHeadingMbl{
  font-family:"Montserrat";
  font-size: 4vw;


}

.landingPageHeading2{
  font-size: 3vw;
  font-family:"Montserrat";

}
.landingPageHeading2Mbl{
  font-size: 6vw;
  font-family:"Montserrat";

}
.background-containerLanding {
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-image: url("../../../assets/h3.jpeg"); */
  background-size: 100% 100%;
  background-position: center top;
  /* display: flex; */
  /* align-items: center; */
  background-repeat: no-repeat;
  /* justify-content: center; */
  /* border-radius: 32px; */
  /* You can add more styling properties as needed */
}

.img-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  /* backgroundColor: 'rgba(0, 0, 0, 0.6)' */
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 1) 100%); */
}
.landingPageBody {
  /* background-color: black; */
  /* width: 100%; */
}

.landingToolbar {
  display: flex;
  justify-content: center;
}

.landingBackgroundHeading {
  color: white;
  z-index: 999;
  /* font-weight: 600; */
  font-size: 18px;
  text-align: center;
}

.landingBackgroundSubheading {
  font-size: 14px;
  text-align: center;
  padding-left: 2vw;
    padding-right: 2vw;
}

.carouselHeading {
  font-weight: 600;
  font-size: 24px;
  color: black;
  text-align: center;
  margin-top: 4vw;
  margin-bottom: 2vw;
}

.linkCss {
  text-decoration: none;
  color: rgba(173,133,77,1);
  white-space: nowrap;
  font-family: "Montserrat";
}

.landingTextBody {
  margin-top: 16vw;
  display: flex;
  justify-content: center;
  /* font-family: "Montserrat",Helvetica,sans-serif;
   */
   font-family: "Montserrat";
  align-items: center;
}

.landingTextBodyMbl {
  margin-top: 66vw;
  display: flex;
  justify-content: center;
  /* font-family: "Montserrat",Helvetica,sans-serif;
   */
   font-family: "Montserrat";
  align-items: center;
}

.bookingDatePicker{
  color: white;
  background-color: transparent;

}

.toolBarMainBody{
  background-color: bisque;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
