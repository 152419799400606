.packageMainPageGridBody {
    display: grid;
    padding-bottom: 4vw;
    grid-template-columns: 4fr 3fr;
    padding-left: 6vw;
  }

  .packageMainPageGridBodyMbl {
    /* display: grid; */
    padding-bottom: 4vw;
    grid-template-columns: 4fr 3fr;
    padding-left: 6vw;
  }
  .packageMainPageGrid1Mbl{
    margin-right: 6vw;
  }
  .MainHeading{
    font-weight: bold;

  }

  .subHeading{
    color: gray;
    font-weight: bold;
    margin-top: 2em;
    margin-bottom: 1em;

  }
  .text{
    color: gray;


  }
  .mblDots1{
    width: 0.5em;
  }

  .MainHeadingItineary{
    font-weight: bold;
    margin-top: 2em;


  }

  .FrequentlyAskedQuestionDataContent1 {
    width: 58vw;
    padding-top: 3vw;
    /* padding-left: 3vw; */
  }

  .mainHeadingPackagename{
    margin-top: 3em;
    font-weight: bold;
  }
  .mainHeadingPackagenameMbl{
    margin-top: 1em;
    font-weight: bold;
  }
  .whyChooseUsMbl{
    box-shadow: 1px 1px 10px 0px gray;
    /* margin-left: 3vw; */
    margin-right: 6vw;
    margin-top: 10vw;
    margin-bottom: 4vw;
    font-weight: bold;
    padding: 8vw;
    z-index: 9999;
    background-color: white;
    /* position: absolute; */
  }
  .whyChooseUs{
    box-shadow: 1px 1px 10px 0px gray;
    margin-left: 3vw;
    margin-right: 6vw;
    margin-top: 2vw;
    font-weight: bold;
    padding: 2vw;
    z-index: 9999;
    background-color: white;
    /* position: absolute; */
  }
  
  .bookingformBody{
    box-shadow: 1px 1px 10px 0px gray;
    margin-left: 3vw;
    margin-right: 6vw;
    margin-top: -8vw;
    padding: 2vw;
    z-index: 9999;
    background-color: white;
    /* position: absolute; */
  }

  .bookingformBodyMbl{
    box-shadow: 1px 1px 10px 0px gray;
    /* margin-left: 3vw; */
    margin-right: 6vw;
    margin-top: 4vw;
    padding: 8vw;
    z-index: 9999;
    background-color: white;
    /* position: absolute; */
  }

  .inputFieldBooking {
    padding-left: 1.625em;
    width: 100%;
    height: 3em;
    border: 1px solid #52608e;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 1.5vw;
    font-family: "Nunito";
    /* font-style: italic; */
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
  }
  .bookingButtonBody{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

  }

  .includeGridBody{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
  }

  .includeGridBodyMbl{
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    
  }

  .priceIncludeMbl{
    font-weight: bold;
    margin-bottom: 2vw;
  }
  .bookPackagecardHeadingMbl{
    font-size: 5.5vw;
    font-weight: bold;

  }

  .bookPackagecardHeading{
    font-size: 1.5vw;
    font-weight: bold;

  }